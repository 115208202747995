// extracted by mini-css-extract-plugin
export var breadcrumbContainer = "LocationsIndexHeader__breadcrumbContainer__BkuLp";
export var column = "LocationsIndexHeader__column__sWiey";
export var downloadIcon = "LocationsIndexHeader__downloadIcon__TxwUf";
export var dropdownBody = "LocationsIndexHeader__dropdownBody__R4HjZ";
export var dropdownBodyRow = "LocationsIndexHeader__dropdownBodyRow__ofvoW";
export var dropdownTrigger = "LocationsIndexHeader__dropdownTrigger__zxTco";
export var editIcon = "LocationsIndexHeader__editIcon__Z4A1Y";
export var filtersContainer = "LocationsIndexHeader__filtersContainer__BYBYH";
export var headerContainer = "LocationsIndexHeader__headerContainer__q6qej";
export var label = "LocationsIndexHeader__label__ma4lP";
export var locationsCount = "LocationsIndexHeader__locationsCount__eueMX";
export var providersDropdown = "LocationsIndexHeader__providersDropdown__xLMUg";
export var providersSearch = "LocationsIndexHeader__providersSearch__dbfdS";
export var reset = "LocationsIndexHeader__reset__q9_Do";
export var row = "LocationsIndexHeader__row__P5DNw";
export var searchField = "LocationsIndexHeader__searchField__rXkMY";
export var title = "LocationsIndexHeader__title__qZv95";
export var titleContainer = "LocationsIndexHeader__titleContainer__iEtFE";