// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexTopProviders from "../../common/locations-index-top-providers/LocationsIndexTopProviders.res.js";
import * as LocationsIndexGlobalContent from "./common/LocationsIndexGlobalContent.res.js";
import * as LocationsIndexContentScss from "./LocationsIndexContent.scss";
import * as LocationsIndexSidebarCallout from "../../common/locations-index-sidebar-callout/LocationsIndexSidebarCallout.res.js";

var css = LocationsIndexContentScss;

function LocationsIndexContent(props) {
  var administrativeDivision = props.administrativeDivision;
  var __localityType = props.localityType;
  var content = props.content;
  var localityName = props.localityName;
  var totalCities = props.totalCities;
  var totalStates = props.totalStates;
  var totalCountries = props.totalCountries;
  var totalProviders = props.totalProviders;
  var totalLocations = props.totalLocations;
  var localityType = __localityType !== undefined ? __localityType : "Global";
  var tmp;
  switch (localityType) {
    case "Global" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalCountries),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Countries",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalCities),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Cities",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    })
              ]
            });
        break;
    case "Country" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalCountries),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Country",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    }),
                totalStates === 0 ? null : JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("div", {
                                children: String(totalStates),
                                className: css.statNumber
                              }),
                          JsxRuntime.jsx("div", {
                                children: totalStates === 1 ? administrativeDivision : props.administrativeDivisionPlural,
                                className: css.statName
                              })
                        ],
                        className: css.stat
                      })
              ]
            });
        break;
    case "State" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalStates),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: administrativeDivision,
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalCities),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Cities",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    })
              ]
            });
        break;
    case "City" :
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalStates),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: administrativeDivision,
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: String(totalCities),
                              className: css.statNumber
                            }),
                        JsxRuntime.jsx("div", {
                              children: "City",
                              className: css.statName
                            })
                      ],
                      className: css.stat
                    })
              ]
            });
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Colocation".toUpperCase(),
                              className: css.serviceCategory
                            }),
                        JsxRuntime.jsx(H2.make, {
                              className: css.localitiesTitle,
                              children: "About " + localityName + " Data Centers Market"
                            }),
                        JsxRuntime.jsx("div", {
                              children: "The " + localityName + " Data Centers Market includes a total of\n          " + String(totalLocations) + " data center" + (
                                totalLocations === 1 ? "" : "s"
                              ) + " and\n          " + String(totalProviders) + " data center\n          provider" + (
                                totalProviders === 1 ? "" : "s"
                              ) + ".",
                              className: css.localitiesSubtitle
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                tmp,
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: String(totalLocations),
                                              className: css.statNumber
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Data Centers",
                                              className: css.statName
                                            })
                                      ],
                                      className: css.stat
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: String(totalProviders),
                                              className: css.statNumber
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Providers",
                                              className: css.statName
                                            })
                                      ],
                                      className: css.stat
                                    })
                              ],
                              className: css.statsContainer
                            }),
                        content !== undefined ? JsxRuntime.jsx("div", {
                                className: css.contentContainer,
                                dangerouslySetInnerHTML: {
                                  __html: content
                                }
                              }) : JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(LocationsIndexGlobalContent.make, {}),
                                className: css.contentContainer
                              })
                      ],
                      className: css.aboutContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(LocationsIndexTopProviders.make, {
                              topProviders: props.topProviders,
                              localityName: localityName
                            }),
                        JsxRuntime.jsx(LocationsIndexSidebarCallout.make, {
                              title: "Looking for Colocation? Our Experts are Ready to Help!",
                              buttonTitle: "Book a Call!"
                            })
                      ],
                      className: css.topProvidersContainer
                    })
              ],
              className: css.locationsContentContainer
            });
}

var make = LocationsIndexContent;

export {
  css ,
  make ,
}
/* css Not a pure module */
