// extracted by mini-css-extract-plugin
export var aboutContainer = "LocationsIndexContent__aboutContainer__H6zpG";
export var column = "LocationsIndexContent__column__bUORK";
export var contentContainer = "LocationsIndexContent__contentContainer__A71ue";
export var h2 = "LocationsIndexContent__h2__AiGGt";
export var localitiesSubtitle = "LocationsIndexContent__localitiesSubtitle__FM0GP";
export var locationsContentContainer = "LocationsIndexContent__locationsContentContainer__NmlzI";
export var row = "LocationsIndexContent__row__wYjng";
export var serviceCategory = "LocationsIndexContent__serviceCategory__ivRwt";
export var stat = "LocationsIndexContent__stat__jD2n4";
export var statName = "LocationsIndexContent__statName__g5mVM";
export var statNumber = "LocationsIndexContent__statNumber__RhK74";
export var statsContainer = "LocationsIndexContent__statsContainer__wO2xa";
export var sticky = "LocationsIndexContent__sticky__DPVKq";
export var topProvidersContainer = "LocationsIndexContent__topProvidersContainer__egU_5";