// extracted by mini-css-extract-plugin
export var column = "LocationsIndexTopProviders__column__RzdrG";
export var localityName = "LocationsIndexTopProviders__localityName__ObA8Y";
export var provider = "LocationsIndexTopProviders__provider__OgFPd";
export var providerData = "LocationsIndexTopProviders__providerData__sGnQ0";
export var providerLogo = "LocationsIndexTopProviders__providerLogo__G3mX2";
export var providerName = "LocationsIndexTopProviders__providerName__Sq_5f";
export var providers = "LocationsIndexTopProviders__providers__NJI3H";
export var providersSidebar = "LocationsIndexTopProviders__providersSidebar__JG21E";
export var row = "LocationsIndexTopProviders__row__r53qh";
export var title = "LocationsIndexTopProviders__title__fTBhG";
export var totalLocations = "LocationsIndexTopProviders__totalLocations__RyQlD";